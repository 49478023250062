import type { NextPage } from 'next';
import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { useQuery } from '@apollo/client';
import { TrackEventInput } from '@updater/ui-tracker';
import { useEssentialsTabCards } from 'flows/core/hooks/use-tab-cards';
import { InitiatePayload } from 'flows/core/types/cards';
import { EssentialLayout } from 'flows/core/components';
import { getSiteBranding } from 'flows/core/components/onboarding/common/queries';
import {
  siteBranding,
  siteBrandingVariables,
} from 'types/generated/siteBranding';
import { useDollyEligibility } from 'flows/core/hooks/use-dolly-eligibility';
import { useGetUserInfoQuery } from '../hooks/use-get-user-info-query';
import { datadogRum } from '@datadog/browser-rum';
import { useFeature } from '@growthbook/growthbook-react';
import {
  MerchPlacement,
  useMerchPlacementTrackedViews,
} from '../components/merch-placement';

const isCreatedBefore24Hours = (createdAt?: string) => {
  if (!createdAt) {
    return false;
  }

  const createdAtDate = new Date(createdAt);
  const msBetweenDates = Math.abs(
    createdAtDate.getTime() - new Date().getTime()
  );
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

  return hoursBetweenDates >= 24;
};

const EssentialsPage: NextPage = () => {
  const {
    toDoCards,
    completedCards,
    loading,
    error: cardError,
  } = useEssentialsTabCards();
  const {
    loading: loadingUser,
    error: errorUser,
    data: dataUser,
  } = useGetUserInfoQuery();

  if (cardError) {
    // If the cards errored we cannot continue. The default error handling will
    // present the user with an option to refresh or log out which is all we can do
    // at this point.
    datadogRum.addError(cardError);
    throw cardError;
  }

  const { Track, trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'essentials_tab',
    pageName: 'essentials_tab',
  });

  // track views for merch placement logic
  useMerchPlacementTrackedViews();

  const { loading: loadingBranding, data: dataBranding } = useQuery<
    siteBranding,
    siteBrandingVariables
  >(getSiteBranding, {
    variables: {
      input: {
        subdomain: null,
      },
    },
  });

  // for tracking dolly eligibility
  useDollyEligibility();

  useEffect(() => {
    if (toDoCards || completedCards) {
      trackEvent({
        object: 'page',
        verb: 'viewed',
        details: {
          ...(toDoCards
            ? {
                toDoCards: toDoCards.map((cardData, position) => ({
                  position,
                  ...cardData,
                })),
              }
            : {}),
          ...(completedCards
            ? {
                completedCards: completedCards.map((cardData, position) => ({
                  position,
                  ...cardData,
                })),
              }
            : {}),
        },
      });
    }
  }, [toDoCards, completedCards, trackEvent]);

  // Get the co-branding information
  const {
    logo = {},
    showBranding = {},
    name = undefined,
  } = (dataBranding?.siteBranding?.entityBrandings &&
  Array.isArray(dataBranding?.siteBranding?.entityBrandings)
    ? dataBranding.siteBranding.entityBrandings.find((entityBranding) =>
        entityBranding && entityBranding.kind === 'property'
          ? entityBranding
          : null
      )
    : {}) || {};

  const {
    firstName: userFirstName = null,
    createdAt: userCreatedAt = null,
    currentMove: usercurrentMove = null,
  } = loadingUser || errorUser ? {} : dataUser?.getUser?.user ?? {};

  const userOwnership = usercurrentMove?.toAddress?.ownership;

  const greetingPrefix = isCreatedBefore24Hours(userCreatedAt)
    ? 'Welcome back'
    : 'Welcome';
  const { value: merchPlacementEnabled } = useFeature(
    'consumer_app_tvi_merch_placement'
  );

  return (
    <Track>
      <EssentialLayout
        greeting={userFirstName ? `${greetingPrefix}, ${userFirstName}` : null}
        brandLogo={
          showBranding && userOwnership === 'RENT'
            ? (logo as { url: any })?.url
            : undefined
        }
        propertyName={userOwnership === 'RENT' ? name : undefined}
        toDoCards={toDoCards as InitiatePayload[]}
        completedCards={completedCards as InitiatePayload[]}
        loading={loading || loadingUser || loadingBranding}
      />
      {merchPlacementEnabled && <MerchPlacement />}
    </Track>
  );
};

export default EssentialsPage;
