import { useTviMerchEligibility } from 'flows/core/hooks/use-tvi-merch-eligibility';
import { useLocalStorage } from 'flows/tvi/hooks/use-local-storage';
import { useMemo, useState } from 'react';
import { MerchPlacementModal } from './MerchPlacementModal';
import { ProductTypes, useFiltersStore } from '@updater/ui-widgets';
import { ServiceabilityProvider } from '@updater/consumer-graph/generated/graphql';
import { useRouter } from 'next/router';
import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';

const MODAL_CONTENT = {
  heading: 'Superfast internet for all',
  ctaText: "Let's do it",
  primaryRoute: '/tvi/landing',
};

const providerLogoMapper: Record<string, string> = {
  att: `/images/tvi/att.png`,
  xfinity: `/images/tvi/xfinity.png`,
  cox: `/images/tvi/cox.png`,
  spectrum: `/images/tvi/spectrum.png`,
};

interface MerchPlacementState {
  trackedViews: number;
  hasViewedOffersPage: boolean;
  hasViewed: boolean;
}

export const MerchPlacement = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const router = useRouter();
  const { setSelections } = useFiltersStore();

  const [merchPlacementStorage, setMerchPlacementStorage] = useLocalStorage(
    'tvi.merchPlacement',
    { trackedViews: 0, hasViewedOffersPage: false, hasViewed: false }
  );
  const { Track, trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'home_services',
  });

  const trackedViews = useMemo(
    () => parseInt(merchPlacementStorage?.trackedViews),
    [merchPlacementStorage?.trackedViews]
  );
  const seenOffersPage = merchPlacementStorage?.hasViewedOffersPage;
  const hasViewed = merchPlacementStorage?.hasViewed;

  const { data, loading } = useTviMerchEligibility();
  const showNothing = data?.showNothing;
  const shouldShowAdPlacement =
    trackedViews > 2 &&
    !hasViewed &&
    !seenOffersPage &&
    !data?.isGreystarOrBozzuto;
  const showBrandedMerchPlacement =
    data?.providerToShow && data?.hasRealOffers && shouldShowAdPlacement;

  const handleClose = () => {
    setMerchPlacementStorage((prevState: MerchPlacementState) => ({
      ...prevState,
      hasViewed: true,
    }));
    setModalOpen(false);
  };

  const handleClickViewOffers = () => {
    setSelections({
      products: data?.productTypes || ([] as ProductTypes[]),
      providers: [
        data?.providerToShow?.provider.code || ({} as ServiceabilityProvider),
      ],
      additionalLanguages: [],
    });
    setMerchPlacementStorage((prevState: MerchPlacementState) => ({
      ...prevState,
      hasViewed: true,
    }));
    trackEvent({
      object: 'service_type',
      verb: 'viewed',
    });
    router.push('/tvi/offers');
  };

  const handleClick = () => {
    setMerchPlacementStorage((prevState: MerchPlacementState) => ({
      ...prevState,
      hasViewed: true,
    }));
    trackEvent({
      object: 'service_type',
      verb: 'viewed',
    });
    router.push('/tvi/landing');
  };

  if (hasViewed || !shouldShowAdPlacement || showNothing) return null;

  if (showBrandedMerchPlacement && !loading) {
    const logoUrl =
      providerLogoMapper[data?.providerToShow?.provider?.code ?? ''];
    return (
      <Track>
        <MerchPlacementModal
          modalOpen={modalOpen}
          handleClose={handleClose}
          handleClick={handleClick}
          handleClickViewOffers={handleClickViewOffers}
          modalContent={{
            ...MODAL_CONTENT,
            providerName: data?.providerToShow?.provider?.displayName || '',
            imageUrl: '/images/tvi/tvi-merch-placement.jpg',
            logoUrl: logoUrl,
          }}
        />
      </Track>
    );
  }
};
