import { useLocalStorage } from 'flows/tvi/hooks/use-local-storage';
import { useEffect } from 'react';

export const useMerchPlacementTrackedViews = () => {
  const [merchPlacementStorage, setMerchPlacementStorage] = useLocalStorage(
    'tvi.merchPlacement',
    { trackedViews: 0, hasViewedOffersPage: false, hasViewed: false }
  );

  useEffect(() => {
    setMerchPlacementStorage((prevState) => ({
      ...prevState,
      trackedViews: merchPlacementStorage.trackedViews + 1,
    }));
  }, []);
};
