import { useMemo } from 'react';
import { useUserData } from './use-profile';
import { TvInternetOffer } from '@updater/consumer-graph';
import { useOffers } from '@updater/ui-widgets';
import { useSiteBranding } from 'flows/resident-onboarding/hooks/use-site-branding';

const brandedProviderCodes = ['att', 'cox', 'xfinity', 'spectrum'];
const isOneOf = (arr: string[]) => {
  return (code: string) => {
    return arr.includes(code);
  };
};

const isBrandedProvider = isOneOf(brandedProviderCodes);

const getProviderToShow = (
  exclusiveProvider,
  featuredProvider,
  providerSummaries
) => {
  if (exclusiveProvider) return exclusiveProvider;
  if (featuredProvider) return featuredProvider;
  const availableProviders = providerSummaries.filter(
    (provider) => provider.availability === 'available'
  );

  if (availableProviders.length > 0) {
    const coreProviders = availableProviders.filter((provider) => {
      return brandedProviderCodes.includes(provider.provider.code);
    });
    const provider = coreProviders.find((provider) => {
      if (provider.provider.code === 'att') return provider;
      if (provider.provider.code === 'spectrum') return provider;
      if (provider.provider.code === 'cox') return provider;
      if (provider.provider.code === 'xfinity') return provider;
    });

    return provider;
  }
};

export const useTviMerchEligibility = () => {
  const { user } = useUserData();

  const { availableOffers, providerSummaries, productTypes } = useOffers({
    input: {
      moveId: user?.currentMove?.id,
    },
    queryOptions: {
      skip: !user?.currentMove?.id,
      fetchPolicy: 'no-cache',
    },
  });

  const siteBranding = useSiteBranding();

  return useMemo(() => {
    if (availableOffers && providerSummaries && siteBranding?.data) {
      const offers = <TvInternetOffer[]>[...(availableOffers ?? [])];

      const featuredProviders = providerSummaries.filter(
        (provider) => provider.provider?.featured
      );
      const exclusiveProviders = providerSummaries.filter(
        (provider) => provider.provider?.exclusive
      );

      const hasAvailableOffers = offers.some(
        (offer) => offer?.products?.internet?.downloadSpeed != null
      );

      const company = siteBranding?.data?.siteBranding?.entityBrandings?.find(
        (entity) => entity?.kind === 'property_management'
      );

      const hasOneExclusiveProvider =
        exclusiveProviders?.length === 1 &&
        isBrandedProvider(exclusiveProviders[0]?.provider?.code || '');
      const hasOneFeaturedProvider =
        featuredProviders?.length === 1 &&
        isBrandedProvider(featuredProviders[0]?.provider?.code || '');

      const exclusiveProvider = hasOneExclusiveProvider
        ? exclusiveProviders[0]
        : undefined;
      const featuredProvider = hasOneFeaturedProvider
        ? featuredProviders[0]
        : undefined;

      const providerToShow = getProviderToShow(
        exclusiveProvider,
        featuredProvider,
        providerSummaries
      );

      let hasRealOffers = false;

      if (providerToShow) {
        // check to make sure there is more than just dummy offers
        const offerCount = offers?.filter(
          (offer) => offer.providerCode === providerToShow?.provider?.code
        );
        if (offerCount.length > 1) {
          hasRealOffers = true;
        }
      }
      const showNothing =
        featuredProviders.length > 1 ||
        exclusiveProviders.length > 1 ||
        (providerToShow && !hasRealOffers);

      return {
        loading: false,
        data: {
          productTypes,
          providerToShow,
          hasRealOffers,
          isGreystarOrBozzuto:
            company?.entityId === 'Greystar' || company?.name === 'Bozzuto',
          showNothing,
        },
      };
    }
    return { loading: true };
  }, [availableOffers, providerSummaries, siteBranding?.data, productTypes]);
};
