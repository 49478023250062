import styled from '@emotion/styled';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Button,
  Image,
  Text,
  Box,
  theme,
  ModalContentProps,
} from '@updater/ui-uds';
import { useEffect, useState } from 'react';

const SImage = styled(Image)`
  position: absolute;
  bottom: -32px;
  right: 0;
  left: 0;
  margin: 0 auto;
  border-radius: 50%;
  max-width: 64px;
`;
type SModalContentProps = ModalContentProps & {
  modalOpen: boolean;
};
const SModalContent = styled(ModalContent)<SModalContentProps>`
  opacity: ${({ modalOpen }) => (modalOpen ? '1' : '0')};
  transform: ${({ modalOpen }) =>
    modalOpen ? 'translate3d(0, 0px,0))' : 'translate3d(0,20px, 0)'};
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
`;

const SModal = styled(Modal)<SModalContentProps>``;

interface ModalContent {
  heading: string;
  logoUrl: string;
  imageUrl: string;
  providerName: string;
  primaryRoute: string;
}

interface MerchPlacementModalProps {
  modalOpen: boolean;
  handleClose: () => void;
  handleClick: () => void;
  handleClickViewOffers: () => void;
  modalContent: ModalContent;
}

export const MerchPlacementModal = ({
  modalOpen,
  handleClose,
  handleClick,
  handleClickViewOffers,
  modalContent,
}: MerchPlacementModalProps) => {
  const [transitionStarted, setTransitionStarted] = useState(false);
  useEffect(() => {
    if (modalOpen) {
      setTransitionStarted(true);
    }
  }, [modalOpen]);
  return (
    <SModal
      isOpen={modalOpen}
      setIsOpen={() => console.log('change')}
      renderAsChild
      modalOpen={transitionStarted}
    >
      <ModalOverlay />
      <SModalContent
        minWidth="380px"
        maxWidth="380px"
        maxHeight={['506px', 'auto', 'auto']}
        modalOpen={transitionStarted}
      >
        <ModalHeader
          minHeight={undefined}
          padding={'none'}
          paddingLeft={'none'}
          paddingRight={'none'}
          paddingTop={'none'}
          hideHeaderBorder
        >
          <ModalCloseButton
            onClick={handleClose}
            onKeyPress={(e) => {
              if (e.key === 'Escape') {
                // resetModal();
                console.log('reset');
              }
            }}
            style={{ top: '16px', right: '16px' }}
            color="#ccc"
          />
          <Image
            src="https://res.cloudinary.com/updater-marketing/image/upload/v1709932736/logo/family_1.png"
            display={['block', 'block', 'block', 'block']}
            width="100%"
            alt="Hero graphic"
          />
          <SImage src={modalContent.logoUrl} />
        </ModalHeader>
        {modalContent?.providerName && (
          <Flex marginTop={'xl'} flexDirection="row" justifyContent="center">
            <Text variant="m" color={theme.colors.primary} paddingRight={'xs'}>
              {modalContent.providerName}
            </Text>
            <Box
              border={`1px solid ${theme.colors.cta}`}
              borderRadius={'xs'}
              paddingX={'xs'}
            >
              <Text variant={'xs'} color={theme.colors.cta}>
                Ad
              </Text>
            </Box>
          </Flex>
        )}
        <ModalBody>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            paddingTop="s"
          >
            <Text variant="mBold" mb={'s'}>
              {modalContent.heading}
            </Text>
            <Text variant="m" mb={'s'} textAlign={'center'}>
              Order now to unlock the comforts of home with lightning-fast
              internet!
            </Text>
            <Button
              data-testid="merch-placement-modal-cta"
              variant="primary"
              isFluidWidth
              onClick={handleClickViewOffers}
            >
              See {modalContent.providerName} offers
            </Button>
          </Flex>
        </ModalBody>
      </SModalContent>
    </SModal>
  );
};
